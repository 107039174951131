import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import SoftButton from "components/SoftButton";
import CloseIcon from '@mui/icons-material/Close';
import SoftTypography from 'components/SoftTypography';

const PaymentErrorModal = ({ isOpen, close }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpen}
            close={close}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontWeight: 'bold',
                }}
            >
                {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.header')}
                <IconButton
                    onClick={() => close()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <SoftTypography
                    sx={{
                        mb: 2,
                        color: '#ea0606',
                        fontSize: '14px',
                    }}
                >
                    {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.important')}
                </SoftTypography>
                <SoftTypography
                    sx={{
                        textDecoration: 'underline',
                        mb: 2,
                        fontSize: '14px',
                    }}
                >
                    {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDoHeader')}:
                </SoftTypography>
                <SoftTypography sx={{ mb: 1, fontSize: '14px' }}>
                    <SoftTypography sx={{ fontWeight: 'bold', display: 'inline', mr: 1, fontSize: '14px' }}>
                        {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo1Header')}:
                    </SoftTypography>
                    {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo1Text')}
                </SoftTypography>
                <SoftTypography sx={{ mb: 1, fontSize: '14px' }}>
                    <SoftTypography sx={{ fontWeight: 'bold', display: 'inline', mr: 1, fontSize: '14px' }}>
                        {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo2Header')}:
                    </SoftTypography>
                    {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo2Text')}
                </SoftTypography>
                <SoftTypography sx={{ mb: 1, fontSize: '14px' }}>
                    <SoftTypography sx={{ fontWeight: 'bold', display: 'inline', mr: 1, fontSize: '14px' }}>
                        {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo3Header')}:
                    </SoftTypography>
                    {t('POLICY_DETAILS.ENDORSEMENTS.paymentError.whatYouCanDo3Text')}
                </SoftTypography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <SoftButton size='small' onClick={close}>
                    <CloseIcon sx={{ marginRight: '0.2rem' }} />
                    {t('COMMON.close')}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

PaymentErrorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default PaymentErrorModal;
