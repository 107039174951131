import { useContext, useState } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from "../../../../shared/const/api-backend";
import { reportError } from 'shared/services/raygunService';

const apiUrl = process.env.REACT_APP_API_PROXY;

const useUpdateCustomerDetails = () => {
    const { setLoading } = useContext(LoaderContext);
    const { showNotification } = useContext(NotificationContext);
    const [updateError, setUpdateError] = useState(null);
    const API = useApi();
    const updateCustomerDetails = async (customerId, updatedCustomer) => {
        setLoading(true);
        try {
            const response = await API.put(`${apiUrl}customers/${customerId}`, { customer: updatedCustomer });
        } catch (error) {
            setUpdateError(error);
            showNotification('CUSTOMERS.ERRORS.updateFailed', 'error');
            reportError(error);
        } finally {
            setLoading(false);
        }
    };

    return { updateCustomerDetails, updateError };
};

export default useUpdateCustomerDetails;
