export const MAX_FIELD_LENGTH = 100;

export const STATES_OPTIONS = [
    {
        value: "ACT",
        label: "Australian Capital Territory"
    },
    {
        value: "NSW",
        label: "New South Wales"
    },
    {
        value: "NT",
        label: "Northern Territory"
    },
    {
        value: "QLD",
        label: "Queensland"
    },
    {
        value: "SA",
        label: "South Australia"
    },
    {
        value: "TAS",
        label: "Tasmania"
    },
    {
        value: "VIC",
        label: "Victoria"
    },
    {
        value: "WA",
        label: "Western Australia"
    }
]
